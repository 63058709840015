import React from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import '../../App.css';

var links ={
  "indian_companies":"",
  "unlisted_companies": "",
  "equity_listing_unlisted_companies":"https://www1.nseix.com/listing/about-equity-listing",
  "fresh_issuance":"",
  "offer_for_sale_unlisted_companies":"",
  "private_placement":"",
  "reits_unlisted_companies":"https://www1.nseix.com/listing/debt/reits",
  "invits_unlisted_companies":"https://www1.nseix.com/listing/debt/invits",
  "debt_unlisted_companies":"",
  "listed_companies":"",
  "secondary_listing":"",
  "equity_listing_listed_company":"https://www1.nseix.com/listing/about-equity-listing",
  "follow_on_offer": "",
  "offer_for_sale_listed_companies":"",
  "qip":"",
  "right_issue":"",
  "preferential_issue": "",
  "depository_receipts":"https://www1.nseix.com/listing/debt/depository-receipts",
  "reits_listed_company":"https://www1.nseix.com/listing/debt/reits",
  "invits_listed_company":"https://www1.nseix.com/listing/debt/invits",
  "debt_listed_company":"",
  "foreign_companies":"",
  "equity_listing_foreign_companies":"https://www1.nseix.com/listing/about-equity-listing",
  "secondary_listing_foreign_companies":"",
  "depository_receipts_foreign_companies":"https://www1.nseix.com/listing/debt/depository-receipts",
  "reits_foreign_companies":"https://www1.nseix.com/listing/debt/reits",
  "invits_foreign_companies":"https://www1.nseix.com/listing/debt/invits",
  "debt_foreign_companies":""
}

const RaisingFund = () => (
<>
<div className="raising-container">
  <div className="raising-title" >
  Introduction on Raising Capital
  </div>
  <div className="raising-para">
  The capital market within the GIFT IFSC serves as a dynamic platform, facilitating companies to bolster their innovation, foster sustainable growth, and pursue their global aspirations. NSE IX offers a conducive environment for companies seeking to raise capital and expand their horizons. We provide support at every stage of fundraising journey from initial consultation and advisory services to the preparation of listing documents and compliance with regulatory requirements, the exchange extends dedicated assistance to companies, ensuring a seamless and efficient listing process.
  With a robust regulatory framework, comprehensive support services, and access to a diverse investor base, companies can confidently embark on their fundraising journey, secure in the knowledge that they have a trusted partner in NSE IX to support their endeavors.
  </div>

</div>

  <div className="tree-container">
  <Tree 
    lineWidth={"2px"}
    lineColor={"black"}
    lineBorderRadius={"10px"}
    label={<div className="tree-box" style={{color:'black', fontWeight:'800', fontSize:'x-large'}}>Indian Companies</div>}
  >
    <TreeNode  label={<div className="tree-box-2" style={{color:'#cc021a', fontWeight:'800', fontSize:'x-large'}}>Unlisted Companies</div>}>
      <TreeNode label={<div className="tree-box-2"> <a href={links["equity_listing_unlisted_companies"]}style={{color:'#cc021a', fontWeight:'600', fontSize:'20px'}}>Equity Listing</a></div>} >
      <TreeNode label={<div className="tree-box-2" style={{color:'#cc021a', fontWeight:'500', textDecoration:'none',fontSize:'20px'}}>Fresh<br></br> Issuance</div>} />
        <TreeNode label={<div className="tree-box-2" style={{color:'#cc021a', fontWeight:'500', textDecoration:'none',fontSize:'20px'}}>Offer for <br></br> Sale</div>} />
        <TreeNode label={<div className="tree-box-2" style={{color:'#cc021a', fontWeight:'500', textDecoration:'none',fontSize:'20px'}}>Private <br></br>Placement</div>} />
        </TreeNode>
        <TreeNode label={<div className="tree-box-2" > <a href={links["reits_unlisted_companies"]}style={{color:'#cc021a', fontWeight:'600',fontSize:'20px'}}>REITs</a></div>} />
      <TreeNode label={<div className="tree-box-2" > <a href={links["invits_unlisted_companies"]}style={{color:'#cc021a', fontWeight:'600',fontSize:'20px'}}>InvITs</a></div>} />
      <TreeNode label={<div className="tree-box-2" style={{color:'#cc021a', fontWeight:'600',fontSize:'20px'}}>DEBT*</div>} />
    </TreeNode>
    <TreeNode label={<div className="tree-box-3" style={{color:'#042f75', fontWeight:'800', fontSize:'x-large'}}>Listed Companies^</div>}>
      <TreeNode label={<div className="tree-box-3" style={{color:'#042f75', fontWeight:'600' , fontSize:'20px'}}>Secondary Listing</div>}></TreeNode>
      <TreeNode label={<div className="tree-box-3"><a href={links["equity_listing_listed_company"]}style={{color:'#042f75', fontWeight:'600', fontSize:'20px'}}>Equity Listing</a></div>}>
        <TreeNode label={<div className="tree-box-3" style={{color:'#042f75',fontSize:'20px'}}>Follow on <br></br>Offer</div>} />
        <TreeNode label={<div className="tree-box-3" style={{color:'#042f75',fontSize:'20px'}}>Offer for <br></br>Sale</div>} />
        <TreeNode label={<div className="tree-box-3" style={{color:'#042f75',fontSize:'20px'}}>QIP</div>} />
        <TreeNode label={<div className="tree-box-3" style={{color:'#042f75',fontSize:'20px'}}> Right <br></br> Issue</div>} />
        <TreeNode label={<div className="tree-box-3" style={{color:'#042f75',fontSize:'20px'}}>Preferential <br></br>Issue</div>} />
      </TreeNode>
      <TreeNode label={<div className="tree-box-3" ><a href={links["depository_receipts"]}style={{color:'#042f75', fontWeight:'600',fontSize:'20px'}}>Depository Receipts</a></div>}></TreeNode>
      <TreeNode label={<div className="tree-box-3" ><a href={links["reits_listed_company"]}style={{color:'#042f75', fontWeight:'600',fontSize:'20px'}}>REITs </a></div>}></TreeNode>
      <TreeNode label={<div className="tree-box-3" ><a href={links["invits_listed_company"]}style={{color:'#042f75', fontWeight:'600',fontSize:'20px'}}>InvITs</a></div>}></TreeNode>
      <TreeNode label={<div className="tree-box-3"  style={{color:'#042f75', fontWeight:'600',fontSize:'20px'}}>DEBT*</div>}></TreeNode>
    </TreeNode>
  </Tree>
  
<div style={{textAlign: 'right', marginRight: '40px', fontSize:'16px' }}>*Under ECB framework</div>
<div style={{textAlign: 'right', marginRight: '40px', fontSize:'16px'  }}>^in-principle approval from Domestic Regulator and Exchanges</div>
   <hr></hr>

  <Tree 
    lineWidth={"2px"}
    lineColor={"black"}
    lineBorderRadius={"10px"}
    label={<div className="tree-box-4" style={{color:'#946a0f', fontWeight:'800', fontSize:'x-large'}}>Foreign Companies</div>}
  >
    <TreeNode label={<div className="tree-box-4"><a href={links["equity_listing_foreign_companies"]}style={{color:'#946a0f', fontWeight:'500', fontSize:'20px'}}>Equity Listing</a></div>}/>
     <TreeNode label={<div className="tree-box-4" style={{color:'#946a0f', fontWeight:'500', fontSize:'20px'}}>Secondary Listing</div>}/>
     <TreeNode label={<div  className="tree-box-4"><a href={links["depository_receipts_foreign_companies"]}style={{color:'#946a0f', fontWeight:'500', fontSize:'20px'}}>Depository Receipts</a></div>}/>
     <TreeNode label={<div className="tree-box-4"><a href={links["reits_foreign_companies"]}style={{color:'#946a0f', fontWeight:'500', fontSize:'20px'}}>REITs</a></div>}/>
     <TreeNode label={<div className="tree-box-4"><a href={links["invits_foreign_companies"]}style={{color:'#946a0f', fontWeight:'500', fontSize:'20px'}}>InvITs</a></div>}/>
     <TreeNode label={<div className="tree-box-4" style={{color:'#946a0f', fontWeight:'500', fontSize:'20px'}}>DEBT</div>}/>
  </Tree>
</div>
</>
);

export default RaisingFund;
