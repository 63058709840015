import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const myStyleWeight = {
    fontWeight: '500' 
  };
  const myStyleSize = {
    fontSize:'12px'
  }

function StreamerTable4({ newData }) {

    return (
        <div className='card' style={{ height: '100%' }}>
            <div className='card-header' style={{ backgroundColor: '#392d7d', color: 'white' }} >
                <b>Market Depth</b>
            </div>
            <div className='card-body'>
                <TableContainer component={Paper}>
                    <Table className="table customize-table" aria-label="simple table">
                        <TableHead>
                            <TableRow >
                                <TableCell style={myStyleWeight}>BQty</TableCell>
                                <TableCell style={myStyleWeight}>Bid Price</TableCell>
                                <TableCell style={myStyleWeight}>Ask Price</TableCell>
                                <TableCell style={myStyleWeight}>AQty</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {newData.map((new_data) => {
                                return (
                                    <>
                                        <TableRow >
                                            <TableCell style={myStyleSize}>{new_data.BQTY_1}</TableCell>
                                            <TableCell style={myStyleSize}>{new_data.BIDPRICE_1}</TableCell>
                                            <TableCell style={myStyleSize}>{new_data.ASKPRICE_1}</TableCell>
                                            <TableCell style={myStyleSize}>{new_data.AQTY_1}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={myStyleSize}>{new_data.BQTY_2}</TableCell>
                                            <TableCell style={myStyleSize}>{new_data.BIDPRICE_2}</TableCell>
                                            <TableCell style={myStyleSize}>{new_data.ASKPRICE_2}</TableCell>
                                            <TableCell style={myStyleSize}>{new_data.AQTY_2}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={myStyleSize}>{new_data.BQTY_3}</TableCell>
                                            <TableCell style={myStyleSize}>{new_data.BIDPRICE_3}</TableCell>
                                            <TableCell style={myStyleSize}>{new_data.ASKPRICE_3}</TableCell>
                                            <TableCell style={myStyleSize}>{new_data.AQTY_3}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={myStyleSize}>{new_data.BQTY_4}</TableCell>
                                            <TableCell style={myStyleSize}>{new_data.BIDPRICE_4}</TableCell>
                                            <TableCell style={myStyleSize}>{new_data.ASKPRICE_4}</TableCell>
                                            <TableCell style={myStyleSize}>{new_data.AQTY_4}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={myStyleSize}>{new_data.BQTY_5}</TableCell>
                                            <TableCell style={myStyleSize}>{new_data.BIDPRICE_5}</TableCell>
                                            <TableCell style={myStyleSize}>{new_data.ASKPRICE_5}</TableCell>
                                            <TableCell style={myStyleSize}>{new_data.AQTY_5}</TableCell>
                                        </TableRow>
                                    </>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default StreamerTable4;