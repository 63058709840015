import React from 'react';
import ShowcaseLayout from './components/grid';
import './components/style.css';

class LiveStremingInfo extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container-fluid mt-0 static_content" style={{ paddingLeft: '0' }}>
                
                <ShowcaseLayout />
            </div>
        )
    }
}
export default LiveStremingInfo;
