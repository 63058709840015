import React, { Component } from 'react';
import niftylogo from '../../public/images/nifty-50.png';
import giftNiftyLogo from '../../public/images/new-logos/Gift-Nifty.png';
import giftNifty50Logo from '../../public/images/new-logos/Gift-Nifty-50.png';
import giftNiftyBankLogo from '../../public/images/new-logos/Gift-Nifty-bank.png';
import giftNiftyITLogo from '../../public/images/new-logos/Gift-Nifty-IT.png';
import giftNiftyFSLogo from '../../public/images/new-logos/Gift-Nifty-fs.png';
import giftNiftyAutoLogo from '../../public/images/new-logos/Gift-Nifty-auto.png';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, ButtonPlay } from 'pure-react-carousel';

class Ticker extends Component {
    constructor() {
        super();
        this.state = {
            nifty: null,
            statusApi: `/api/market-status`,
            marketStatus: '',
            statusColor: '',
            currentDate: "",
            nextTradingDate: ""
        }
    };

    componentDidMount() {
        try {
            this.loadMarketRate();
            this.loadMarketStatus(this.state.statusApi);
            // setInterval(this.loadMarketRate, 30000);
        } catch (e) {
            console.log(e);
        }
    }
    loadMarketStatus(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                this.setState({ marketStatus: data.marketstatus, statusColor: data.statusColor, currentDate: data.currentDate, nextTradingDate: data.nxtTradeDate })
            })
            .catch((e) => { console.log("error in loading market status", e) })
    }
    loadMarketRate() {
        fetch(`/api/nifty-market-rate`)
            .then(resp => resp.json())
            .then((data) => {
                if (data[0]) {
                    this.setState({ nifty: data[0] });
                }
            })
            .catch((e) => { console.log("error in loading ticker", e) })
    }

    render() {

        return <>
            {
                this.state.nifty ?
                    <div className="newsupdate d-flex pt-3 pb-3">


                        <div className="pr-2 pl-1 borderright niftylogo">
                            {/* <marquee direction="up"> */}
                            <img src={niftylogo} alt="niftylogo" />
                        </div>



                        <div className="pr-2 pl-2 ">
                            <p className="newsupdate-font-blue">{this.state.nifty.CURRVALUE} <span>{this.state.nifty.CHANGE < 0 ? <ArrowDropDownIcon className="rate-down" /> : <ArrowDropUpIcon className="rate-up" />}</span></p>
                            <p className="newsupdate-font">
                                <span className={"float-left " + (this.state.nifty.CHANGE > 0 ? "newsupdate-font-green" : "newsupdate-font-red")}>{this.state.nifty.CHANGE}</span>
                                <span className={"float-right " + (this.state.nifty.CHANGE > 0 ? "newsupdate-font-green" : "newsupdate-font-red")}> ({this.state.nifty.PERCHANGE}%)</span>
                            </p>
                        </div>

                        <div className="pr-3 pl-3 borderright niftylogo logo-slider flex-column">
                        <div className='image-container'>
                                <span>
                                    <img src={giftNiftyLogo} />
                                </span>
                                {/* <span>
                                    <img src={giftNifty50Logo} />
                                </span> */}
                                <span>
                                    <img src={giftNiftyBankLogo} />
                                </span>
                                <span>
                                    <img src={giftNiftyITLogo} />
                                </span>
                                <span>
                                    <img src={giftNiftyFSLogo} />
                                </span>
                                {/* <span>
                                    <img src={giftNiftyAutoLogo} />
                                </span> */}
                            </div>
                        </div>

                        <div className="pl-3 blink-news mktsts">
                            <p className={this.state.statusColor === 'green' ? "newsupdate-font-green text" : "newsupdate-font-red text"}>{this.state.marketStatus}</p>

                            {this.state.statusColor === "green" ? <p className="newsupdate-font-green date">Current Day <br /> Date : {this.state.currentDate}</p> :

                                <p className="newsupdate-font-nocolor date">Next Trading Date <br /> Date : {this.state.nextTradingDate}</p>}

                        </div>
                    </div> :
                    <span />
            }
        </>
    }
}

export default Ticker;
