import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

export default function Graph({dataX}) {


const options = {
  chart:{
    type: "line"
  },
  rangeSelector: {
  enabled:false,
  },
  plotOptions: {
    series: {
      enableMouseTracking: true
    }
  },
  yAxis:{
    title: '',
    width: 40
  },
  scrollbar:{
    enabled: false,
  },
  xAxis: {
    type: "datetime",
    dateTimeLabelFormats: {
      year: '%Y'
  }, tooltip: {
      xDateFormat: '%e %b %Y'
  }
  },
  series: [
    {
      data:dataX,
      tooltip:{
        valurDecimals :2,
      },
      // color: 'green'
    }
  ],
    navigator: {
    enabled: false ,
  },
  legend:{
    enabled: false
  }  ,
  credits: {    
    enabled: false  
    },
    exporting: {
      enabled: true
  }, navigation: {
    buttonOptions: {
        enabled: false
    }
},
};

  return (
    <div className='Graph'>
      <HighchartsReact points={2}
      highcharts={Highcharts}
        constructorType={'stockChart'}
        options={options} />
    </div>)
}
