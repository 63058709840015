import { ReactComponent as PDFIcon } from "../../public/images/pdf-icon.svg";
import { ReactComponent as ZIPIcon } from "../../public/images/zip-icon.svg";
import { ReactComponent as XLSXIcon } from "../../public/images/xlsx-icon.svg";
import { ReactComponent as DnldIcon } from "../../public/images/dnld-icon.svg";
import { ReactComponent as DocIcon } from "../../public/images/doc-icon.svg";
import parse from "html-react-parser";

export const getDownloadIcon = (uri, id) => {
  if (uri && uri.includes("?VersionId")) {
    uri = uri.split("?")[0];
  }
  let extension = uri && uri.substring(uri.lastIndexOf(".") + 1);
  if (extension) {
    extension = extension.toUpperCase();
    switch (extension) {
      case "PDF":
        return <PDFIcon id={id || "pdf_icon"} className="pdficon mr-1" />;
      case "ZIP":
        return <ZIPIcon id={id || "zip_icon"} className="zipicon mr-1" />;
      case "XLSX":
        return <XLSXIcon id={id || "xlsx_icon"} className="xlsxicon mr-1" />;
      case "CSV":
        return <XLSXIcon id={id || "csv_icon"} className="xlsxicon mr-1" />;
      case "DOC":
        return <DocIcon id={id || "doc_icon"} className="docicon mr-1" />;
      case "DOCX":
        return <DocIcon id={id || "docx_icon"} className="docicon mr-1" />;
      default:
        return <DnldIcon id={id || "dnd_icon"} className="dnldIcon mr-1" />;
    }
  } else {
    return <DnldIcon id={id || "dld_icon"} className="dnldIcon" />;
  }
};
export const removePtag = (content) => {
  if (content && content.includes("<p>")) {
    let title = content
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "")
      .replace(/[\n\r]/g, "");
    return title;
  }
  return content;
};
export const removeStrongtag = (content) => {
  if (content && content.includes("<strong>")) {
    let title = content.replace("<strong>", "").replace("</strong>", "");
    return title;
  }
  return content;
};
export const convertPrice = (val) => {
  if (val === "-" || val === 0 || val === " - ") {
    return val;
  } else if (val === null) {
    return "-";
  } else {
    val = val.toLocaleString();
    return val;
  }
};



export const businessGrowtNumPrecision = (val) =>{
  if (val === "-" || val === " - ") {
    return val;
  } else if (val === null) {
    return "-";
  } else {

    const factor = Math.pow(10,2);
    const tempValue = val * factor;
    const integerPart = Math.floor(tempValue);
    const nextDigit = Math.floor((tempValue-integerPart) * 10);

    if(nextDigit > 5){

      val = Math.ceil(tempValue) / factor;
      val = new Intl.NumberFormat('en-IN', { maximumFractionDigits: 2 }).format(val)
      return val;
    }else {
      val = tempValue/factor;
      val = new Intl.NumberFormat('en-IN', { maximumFractionDigits: 2 }).format(val)
      return val;
    }
  }
}



export const convertNumberPrecision = (val, precision) => {
  if (val === "-" || val === " - ") {
    return val;
  } else if (val === null) {
    return "-";
  } else {
   	const factor = Math.pow(10,2);
    return Math.ceil(val*factor)/factor;
  }
};
export const parseAsHtml = (content) => {
  if (!content) {
    return "";
  } else if (typeof content === "string") {
    return parse(content.replace(/[\n\r\t]/g, ""));
  } else {
    return content;
  }
};

export const getTitleFileExtension = (uri) => {
  if (uri && uri.includes("?VersionId")) {
    uri = uri.split("?")[0];
  }
  let extension = uri && uri.substring(uri.lastIndexOf(".") + 1);
  let title =
    uri &&
    uri.substring(uri.lastIndexOf("/") + 1, uri.lastIndexOf(".")) + " | ";
  if (extension) {
    extension = extension.toUpperCase();
    switch (extension) {
      case "PDF":
        return title + "PDF";
      case "ZIP":
        return title + "ZIP";
      case "XLSX":
        return title + "XLSX";
      case "CSV":
        return title + "CSV";
      case "DOC":
        return title + "DOC";
      case "DOCX":
        return title + "DOCX";
      default:
        return title + "FILE";
    }
  } else {
    return title + "FILE";
  }
};

export const getToggleVal = (val, toggleState) => {
  if (toggleState === "Thousands") {
    return val / 1000;
  } else if (toggleState === "Millions") {
    return val / 1000000;
  } else {
    return val / 1000000000;
  }
};

export const getToggleHeader = (toggleState) => {
  if (toggleState === "" || toggleState === "Actual") {
    return "($)";
  }
  if (toggleState === "Thousands") {
    return "($ Thousands)";
  } else if (toggleState === "Millions") {
    return "($ Millions)";
  } else {
    return "($ Billions)";
  }
};
function getHostname(url) {
  var hostname;
  if (url.indexOf("//") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }
  hostname = hostname.split(":")[0];
  hostname = hostname.split("?")[0];

  return hostname;
}

export const externalLinkDisclaimer = (e) => {
  let hrefLink = e.target.href;
  if (hrefLink && !hrefLink.includes("static")) {
    if (getHostname(hrefLink) !== getHostname(window.location.host)) {
      if (
        window.confirm(
          "You are about to leave the Website of International Stock Exchange " +
            "of India(NSEIFSC).\nYou have selected a link that will take you away from the NSEIFSC " +
            "website. This site may be maintained by an entity other than NSE or its Group Companies. " +
            "NSEIFSC is not responsible for the content of the website you are visiting from the " +
            "selected link.\n You are aware and you expressly acknowledge and agree that the linked " +
            "site may not be under the control of NSEIFSC and NSE is shall not be responsible for " +
            "the contents of such site or any link or any link contained in such site, or any changes " +
            "or updates to such website. NSEIFSC shall not be responsible or liable in manner whatsoever " +
            "for any payments that you make for any services that you may avail from such website or " +
            "the selected link.\nNSEIFSc is not responsible for the accuracy, appropriateness or the " +
            "reliability of the data or content available on the website you are visiting from the " +
            "selected link. Unless expressly mentioned, NSEIFSC does not endorse any views, data " +
            "and /or content available on the selected website.\nOnce you visit the website from  " +
            "the selected link, the policies/terms/conditions of such selected website may be " +
            "applicable on you.\nThe availability of this link on the NSE website shall not " +
            "be construed as advertisement and/or endorsement if such website by NSE in any " +
            "manner whatsoever.\n If you decide to visit such site, you agree and acknowledge " +
            "do so at your own risk and it is your responsibility to take all protective measures " +
            "to guard against virus or any destructive elements that you may encounter. "
        )
      ) {
        return true;
      } else {
        e.stopPropagation();
        e.preventDefault();
      }
    }
  }
};
// export default {
//     getDownloadIcon,
//     removePtag,
//     removeBtag
// }
