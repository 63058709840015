import React, { Component } from 'react';
import CustomTable from './components/custom-table';
import EventGallery from './components/event-gallery'
import NotFound from './notfound';
import LinearProgress from '@material-ui/core/LinearProgress'
import LeftNavigationContainer from './components/LeftNavigationContainer'
import NavTabs from './components/nav-tabs';
import CollapsibleTabs from './components/collapsible-tabs';
import ProductInfoCard from './components/info-card';
import ActiveTabs from './components/active-tabs';
import VideoCard from './components/video-card';
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {IconButton} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { getDownloadIcon, removePtag, parseAsHtml, externalLinkDisclaimer } from './components/util'
import Circulars from './circulars';
import PressRelease from './press-release';
import MediaCoverage from './media-coverage';
// import ListOfUnderlying from './list-of-underlying';
import MostActive from './most-active';
import '.././public/css/styles.scss';
import clsx from 'clsx';
import styles from './styles/static-page';
import CorporateInformation from './corporate-information';
import Holidays from './holidays';
import Reports from './reports';
import HistoricalDerivative from './historical-derivative';
import BusinessGrowth from './business-growth-aws';
import BusinessGrowthRevised from './business-growth-revised';
import DerivativesWatch from './derivatives-watch';
import Favorites from './favorites';
import ScrollTop from './scroll-top';
import LiquidityEnhancementStatistics from './liquidity-enhancement-scheme-statistics';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MetaData from './meta-data';
import ImageSlider from './components/img-gallery-slider';
import ContactUsCard from './components/contactus-card';
import USStocks from './us-stocks';
import IssuerImageSlider from './components/issuer-image-slider';
import esgDownload from "../public/images/esg-download.png"
// import { parse } from 'date-fns';
import IssuerDetails from "./components/issuer-details";
import EsgBonds from './esg-bonds';
import OptionChain from './option-chain';
import HistoricalNlt from './historical-nlt'
//import OptionChain from './option-chain';
//import HistoricalNlt from './historical-nlt'
// import ISXSite from './ISXSite';
//import HistoricalNlt from './historical-nlt';
import NseCorporateAnnouncement from './components/nse-corporate-announcement';
import LatencyStatistics from './latency-statistics';
import ListingAtNSEIX from './components/raising-funds';

//Setting object array with breadcrumb details,Url,Keys for Dynamic Pages
const dynamics = {
    "/resources/circulars": {
        View: Circulars,
        breadcrumTitle: "Circulars",
        contentTitle: "Circulars",
        pageKey: "Circulars",
        menuKey: "Resources",
        breadCrum: [{
            "field_breadcrumb": [
                { "uri": "/resources/circulars", "title": "Resources" },
                { "uri": "/resources/circulars", "title": "Exchange Communication" },
                { "uri": "/resources/circulars", "title": "Circulars" }]
        }],
        drawerOpen: true,
    },
    "/resources/press-release": {
        View: PressRelease,
        breadcrumTitle: "Press Release",
        contentTitle: "Press Release",
        pageKey: "PressReleases",
        menuKey: "Resources",
        breadCrum: [{
            "field_breadcrumb": [
                { "uri": "/resources/press-release", "title": "Resources" },
                { "uri": "/resources/press-release", "title": "Exchange Communication" },
                { "uri": "/resources/press-release", "title": "Press Release" }]
        }],
        drawerOpen: true,
    },
    "/resources/media-coverage": {
        View: MediaCoverage,
        breadcrumTitle: "Media Coverage",
        contentTitle: "Media Coverage",
        pageKey: "MediaCoverage",
        menuKey: "Resources",
        breadCrum: [{
            "field_breadcrumb": [
                { "uri": "/resources/media-coverage", "title": "Resources" },
                { "uri": "/resources/media-coverage", "title": "Exchange Communication" },
                { "uri": "/resources/media-coverage", "title": "Media Coverage" }]
        }],
        drawerOpen: true,
    },
    "/listing/corporate-information": {
        View: CorporateInformation,
        breadcrumTitle: "Corporate Information",
        contentTitle: "Corporate Information",
        pageKey: "CorporateInformation",
        menuKey: "Listing",
        breadCrum: [{
            "field_breadcrumb": [
                { "uri": "/listing/corporate-information", "title": "Listing" },
                { "uri": "/listing/corporate-information", "title": "Listing Information" },
                { "uri": "/listing/corporate-information", "title": "Corporate Information" }]
        }],
        drawerOpen: true,
    },
    "/resources/holidays": {
        View: Holidays,
        breadcrumTitle: "Holidays",
        contentTitle: "Holidays",
        pageKey: "Holidays",
        menuKey: "Resources",
        breadCrum: [{
            "field_breadcrumb": [
                { "uri": "/resources/holidays", "title": "Resources" },
                { "uri": "/resources/holidays", "title": "Exchange Communication" },
                { "uri": "/resources/holidays", "title": "Holidays" }]
        }],
        drawerOpen: true,
    },
    // "/markets/about-our-products/list-of-underlying/equity-derivatives": {
    //     View: ListOfUnderlying,
    //     breadcrumTitle: "Equity Derivatives",
    //     contentTitle: "Equity Derivatives",
    //     pageKey: "AboutourProducts",
    //     menuKey: "Markets",
    //     breadCrum: [{
    //         "field_breadcrumb": [
    //             { "uri": "/products-ifsc", "title": "Markets" },
    //             { "uri": "/products-ifsc", "title": "All Products" },
    //             { "uri": "/products-ifsc", "title": "About our Products" },
    //             { "uri": "/products-ifsc", "title": "List Of Underlying" },
    //             { "uri": "/markets/about-our-products/list-of-underlying/equity-derivatives", "title": "Equity Derivatives" },
    //         ]
    //     }],
    //     drawerOpen: true,
    // },
    "/markets/most-active": {
        View: MostActive,
        breadcrumTitle: "Most Active Contracts",
        contentTitle: "Most Active Contracts",
        pageKey: "MostActive",
        menuKey: "Markets",
        breadCrum: [{
            "field_breadcrumb": [
                { "uri": "/markets/most-active", "title": "Markets" },
                { "uri": "/markets/most-active", "title": "Live Watch" },
                { "uri": "/markets/most-active", "title": "Most Active Contracts" }]
        }],
        drawerOpen: false,
    },
    "/markets/reports": {
        View: Reports,
        breadcrumTitle: "Reports",
        contentTitle: "Reports",
        pageKey: "Reports",
        menuKey: "Markets",
        breadCrum: [{
            "field_breadcrumb": [
                { "uri": "/markets/reports", "title": "Markets" },
                { "uri": "/markets/reports", "title": "Live Watch" },
                { "uri": "/markets/reports", "title": "Reports" }]
        }],
        drawerOpen: true,
    },
    "/markets/historical-data": {
        View: HistoricalDerivative,
        breadcrumTitle: "Historical Data",
        contentTitle: "Historical Data",
        pageKey: "HistoricalData",
        menuKey: "Markets",
        breadCrum: [{
            "field_breadcrumb": [
                { "uri": "/markets/derivatives-watch", "title": "Markets" },
                { "uri": "/markets/historical-data", "title": "Live Watch" },
                { "uri": "/markets/historical-data", "title": "Historical Data" }]
        }],
        drawerOpen: false,
    },
    "/markets/historical-nlt": {
      View: HistoricalNlt,
      breadcrumTitle: "Historical Data for NLT/Block Trade",
      contentTitle: "Historical Data for NLT/Block Trade",
      pageKey: "HistoricalDataNlt",
      menuKey: "Markets",
      breadCrum: [{
          "field_breadcrumb": [
              { "uri": "/markets/derivatives-watch", "title": "Markets" },
              { "uri": "/markets/historical-nlt", "title": "Historical Data for NLT/Block Trade" }]
      }],
      drawerOpen: false,
  },
    "/markets/turnover-business-growth": {
        View: BusinessGrowth,
        breadcrumTitle: "Business Growth",
        contentTitle: "Business Growth",
        pageKey: "BusinessGrowth",
        menuKey: "Markets",
        breadCrum: [{
            "field_breadcrumb": [
                { "uri": "/markets/turnover-business-growth", "title": "Markets" },
                { "uri": "/markets/turnover-business-growth", "title": "Live Watch" },
                { "uri": "/markets/turnover-business-growth", "title": "Business Growth" }]
        }],
        drawerOpen: false,
    },
    "/business-growth-revised": {
        View: BusinessGrowthRevised,
        breadcrumTitle: "Business Growth",
        contentTitle: "Business Growth",
        pageKey: "BusinessGrowth",
        menuKey: "Markets",
        breadCrum: [{
            "field_breadcrumb": [
                { "uri": "/derivatives-watch", "title": "Markets" },
                { "uri": "/derivatives-watch", "title": "Live Watch" },
                { "uri": "/turnover-business-growth", "title": "Business Growth" }]
        }],
        drawerOpen: false,
    },
    "/markets/derivatives-watch": {
        View: DerivativesWatch,
        breadcrumTitle: "Derivatives Watch",
        contentTitle: "Derivatives Watch",
        pageKey: "DerivativesWatch",
        menuKey: "Markets",
        breadCrum: [{
            "field_breadcrumb": [
                { "uri": "/markets/derivatives-watch", "title": "Markets" },
                { "uri": "/markets/derivatives-watch", "title": "Live Watch" },
                { "uri": "/markets/derivatives-watch", "title": "Derivatives Watch" }]
        }],
        drawerOpen: false,
    },
    "/markets/reports/favorites": {
        View: Favorites,
        breadcrumTitle: "Favorites",
        contentTitle: "Favorites",
        pageKey: "Favorites",
        menuKey: "Markets",
        breadCrum: [{
            "field_breadcrumb": [
                { "uri": "/markets/reports/favorites", "title": "Markets" },
                { "uri": "/markets/reports/favorites", "title": "Live Watch" },
                { "uri": "/markets/reports", "title": "Reports" },
                { "uri": "/markets/reports/favorites", "title": "Favorites" },]
        }],
        drawerOpen: true,
    },
    "/markets/about-our-products/equity-derivatives/liquidity-enhancement-scheme-statistics": {
        View: LiquidityEnhancementStatistics,
        breadcrumTitle: "Liquidity Enhancement Scheme Statistics",
        contentTitle: "Liquidity Enhancement Scheme Statistics",
        pageKey: "LES",
        menuKey: "Markets",
        breadCrum: [{
            "field_breadcrumb": [
                { "uri": "/markets/about-our-products/equity-derivatives/liquidity-enhancement-scheme-statistics", "title": "Markets" },
                { "uri": "/markets/about-our-products/equity-derivatives/liquidity-enhancement-scheme-statistics", "title": "All Products" },
                { "uri": "/markets/about-our-products/equity-derivatives/liquidity-enhancement-scheme-statistics", "title": "About our Products" },
                { "uri": "/markets/about-our-products/equity-derivatives/liquidity-enhancement-scheme-statistics", "title": "Liquidity Enhancement Scheme Statistics" },
            ]
        }],
        drawerOpen: true,
    },
    "/markets/global-stocks": {
        View: USStocks,
        breadcrumTitle: "Global Stocks",
        pageKey: "Global Stocks",
        menuKey: "Markets",
        contentTitle: "Global Stocks",
        breadCrum: [{
            "field_breadcrumb": [
                { "uri": "/markets/global-stocks", "title": "Markets" },
                { "uri": "/markets/global-stocks", "title": "Live Watch" },
                { "uri": "/markets/global-stocks", "title": "Global Stock" }]
        }],
        drawerOpen: false,
    },
    "/listing/esg-bonds/issuer-details": {
        View: IssuerDetails,
        breadcrumTitle: "Issuer Details",
        contentTitle: "Issuer Details",
        pageKey: "IssuersDetails",
        menuKey: "Listing",
        breadCrum: [{
            "field_breadcrumb": [
                { "uri": "/listing/debt/debt-listing", "title": "Listing" },
                { "uri": "/listing/esg-bonds/issuer-details", "title": "Listing Information" },
                { "uri": "/listing/esg-bonds/issuer-details", "title": "Issuer Details" }]
        }],
        drawerOpen: false,
    },
    "/listing/esg-bonds": {
        View: EsgBonds,
        breadcrumTitle: "ESG Bonds",
        contentTitle: "ESG Bonds",
        pageKey: "ListOfEsgBonds",
        menuKey: "Listing",
        breadCrum: [{
            "field_breadcrumb": [
                { "uri": "/listing/debt/debt-listing", "title": "Listing" },
                { "uri": "/listing/debt/debt-listing", "title": "Raising Capital" },
                { "uri": "/listing/debt/esg-bonds", "title": "ESG Bonds" },]
        }],
        drawerOpen: false,
    },

    "/markets/option-chain": {
      View: OptionChain,
      breadcrumTitle: "Option Chain",
      contentTitle: "Option Chain",
      pageKey: "OptionChain",
      menuKey: "Markets",
      breadCrum: [{
          "field_breadcrumb": [
              { "uri": "/markets/option-chain", "title": "Markets" },
              { "uri": "/markets/option-chain", "title": "Live Watch" },
              { "uri": "/markets/option-chain", "title": "Option Chain" }]
      }],
      drawerOpen: false,
  },

  //   "/listing/ISX1": {
  //     View: ISXSite,
  //     breadcrumTitle: "International Sustainability Exchange",
  //     contentTitle: "International Sustainability Exchange",
  //     // pageKey: "ISX",
  //     // menuKey: "Listing",
  //     breadCrum: [{
  //         "field_breadcrumb": []
  //     }],
  //     // drawerOpen: false,
  // }

  "/listing/nse-corporate-announcement": {
    View: NseCorporateAnnouncement,
    breadcrumTitle: "Corporate Announcement",
    contentTitle: "Corporate Announcement",
    pageKey: "CorporateAnnouncement",
    menuKey: "Listing",
    breadCrum: [{
        "field_breadcrumb": [

	{ "uri": "/listing/nse-corporate-announcement", "title": "Corporate Announcements" }
]
    }],
    drawerOpen: false,
},

 "/technology/latency-statistics": {
   View: LatencyStatistics,
   breadcrumTitle: "Latency Statistics",
   contentTitle: "Latency Statistics",
   pageKey: "LatencyStatistics",
   menuKey: "Technology",
   breadCrum: [{
       "field_breadcrumb": []
   }],
   drawerOpen: true,
 },
"/listing/listing-At-NSEIX":{
  View: ListingAtNSEIX,
  breadcrumTitle: "Listing At NSEIX",
  contentTitle: "Listing At NSEIX",
  pageKey: "ListingAtNSEIX",
  menuKey: "Listing",
  breadCrum: [{
    "field_breadcrumb" : []
  }],
  drawerOpen: false,
},

};


class StaticPage extends Component {
    _isDataLoaded = false;
    _isMounted = false;
    _LeftPaneUpdated = false;
    _leftPaneRef = React.createRef();
    constructor(props) {
        super(props);
        const queryparams = new URLSearchParams(props.location.search);
        this.state = {
            headerRef: props.headerRef,
            content: [],
            midContent: "",
            contentTitle: "",
            updatedTime: "-",
            pathname: props.pathname,
            cmsApi: `/api/contents/page?url=${props.pathname}`,
            mediaInnerPgApi: `/api/media/page?url=${props.pathname}`,
            drawerOpen: true,
            pagekey: "",
            menukey: "",
            notFound: false,
            breadCrum: [],
            showLeftPane: false,
            innerPage: false,
            metadetails: [],
            queryparams : `${queryparams.get("q") || ''}`,
        };
    }

    handleDrawerClose = () => {
        if (dynamics[this.state.pathname]) {
            //For updating pages with cards - if drawer close - show 4 cards
            const { View } = dynamics[this.state.pathname];
            this.setState(state => ({
                drawerOpen: false,
                midContent: <View drawerOpen={false} />,
            }))
        }
        else {
            this.setState(state => ({
                drawerOpen: false
            }))
        }
    };
    handleDrawerOpen = () => {
        if (dynamics[this.state.pathname]) {
            //For updating pages with cards - if drawer open - show 3 cards
            const { View } = dynamics[this.state.pathname];
            this.setState(state => ({
                drawerOpen: true,
                midContent: <View drawerOpen={true} />,
            }))
        }
        else {
            this.setState(state => ({
                drawerOpen: true
            }))
        }
    };


    componentDidMount() {
        try {
            this._isMounted = true;

            //check if pagic is dynamic/static/media inner page

            //For Dynamic Pages
            if (dynamics[this.state.pathname]) {
                const { View } = dynamics[this.state.pathname];
                const page = dynamics[this.state.pathname];
                this.state.headerRef.current.updateTitle(page.breadcrumTitle, page.contentTitle, page.breadCrum);
                this.state.headerRef.current.activeMenu(page.menuKey,page.pageKey);
                this._isDataLoaded = true

                this.setState({
                    contentTitle: page.contentTitle,
                    breadCrum: page.breadCrum,
                    midContent: <View drawerOpen={page.drawerOpen} />,
                    showLeftPane: true,
                    drawerOpen: page.drawerOpen,
                });
                this._leftPaneRef.current.updatekeys({
                    pagekey: page.pageKey || 'AboutUs',
                    menukey: page.menuKey || 'AboutUs',
                });
            }

            //For Media inner pages
            else if (this.state.pathname.includes('/media/')) {
                this.loadMediaData(this.state.mediaInnerPgApi);
            }

            //For Static - Drupal pages
            else {
                this.loadData(this.state.cmsApi);
            }

        } catch (e) {
            console.log(e);
        }
    }

    //Fetches api response for CMS/Static pages
    loadData(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                this._isDataLoaded = true
                this.setState({
                    updatedTime: data.created,
                    pagekey: data.content.field_key,
                    menukey: data.content.field_menukey,
                    showLeftPane: data.content.field_key ? true : false
                });

                this.setState({ content: data.content.field_components || {} });
                this.setState(this.getMidComponent(
                    data.content.field_components, data.content.field_key
                ));
            })
            .catch((e) => {
                this._isDataLoaded = true;
                console.log("error in loading mid content", e);

                this.setState({ midContent: <NotFound headerRef={this.state.headerRef}/>, notFound: true, drawerOpen: false })
            });
    }

    //Fetches api response for inner media pages
    loadMediaData(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                this._isDataLoaded = true
                this.setState({
                    updatedTime: data.created,
                    pagekey: data.content.field_key,
                    menukey: data.content.field_menukey,
                    showLeftPane: data.content.field_key ? true : false,
                    innerPage: true,
                })
                this.setState({ content: data.content.field_components || {} });
                this.setState(this.getMediaMidComponent(
                    data.content.field_media
                ));
            })
            .catch((e) => {
                this._isDataLoaded = true;
                console.log("error in loading mid content", e);
                this.setState({ midContent: <NotFound headerRef={this.state.headerRef}/>, notFound: true, drawerOpen: false })
            });
    }

    // hyphenizeString = str => str.split(' ').join('-');

    // getComponentsFromPageContent = (
    //     pageContent = {},
    //     componentKey = "field_components",
    //     templatePrefix = ""
    // ) => {
    //     const content = pageContent || {};
    //     if (!Array.isArray(content[componentKey])) {
    //         return [];
    //     }

    //     return content[componentKey]
    //         .map((obj) => {
    //             const key = Object.keys(obj)[0];
    //             if (!key) {
    //                 return null;
    //             }
    //             return {
    //                 template: `${templatePrefix}${this.hyphenizeString(key).toLowerCase()}`,
    //                 data: obj[key],
    //             };
    //         })
    //         .filter(Boolean);
    // };


    //Set relevant tab active on page load when searching keyword present in that tab content
    getActiveTab = (tabcont) => {
        if( this.state.queryparams !== '' &&
            JSON.stringify(tabcont.field_tab_view).toLowerCase().includes(this.state.queryparams.toLowerCase())){
            return tabcont.field_tab_view[0].field_title;
        }
        return undefined;
        }

    //Loops over cms page Api response & pushes (UI + content)
    getMidComponent = (pageContent, pgkey) => {
        let midContent = [];
        let contentTitle = "";
        let breadcrumTitle = "";
        let breadCrum = "";
        let pagkey = pgkey;
        let breadCrumImg = [];
        let metadetails = [];
        let contactusview = [];

                let AddressViewData = [];
                let PhoneNMailViewData = [];
        pageContent.forEach((cont, idx) => {
          let skipPara = false,
            skipuri = false;
          Object.keys(cont).forEach((ke, jdx) => {
            switch (ke) {
              case "field_breadcrumb_image":
                breadCrumImg = cont[ke];
                break;
              case "field_breadcrumb_view":
                breadCrum = cont[ke];
                break;
              case "field_breadcrumb_title":
                breadcrumTitle = removePtag(cont[ke]);
                break;
              case "url":
                //Checking if content is for Membership Card UI or other type
                if (cont.alt && cont.height && cont.width && cont.uri) {
                  midContent.push(
                    <div className="col-md-4 my-3" key={`sc${idx}${jdx}`}>
                      <a
                        href={cont.uri || "/#"}
                        key={`sc${idx}${jdx}`}
                        className="membership-card-link"
                        title={
                          removePtag(cont.field_title) || removePtag(cont.title)
                        }
                      >
                        <div className="membership-img">
                          <img alt={cont.alt} src={cont.url}/>
                        </div>
                        <h3>
                          {removePtag(cont.field_title) ||
                            removePtag(cont.title)}
                        </h3>
                      </a>
                    </div>
                  );

                  skipPara = true;
                  skipuri = true;
                } else if (cont.alt && cont.height && cont.width && !cont.uri) {
                  midContent.push(
                    <div className="col-md-4 my-3" key={`sc${idx}${jdx}`}>
                      {/* <a href={cont.uri || "/#"} key={`sc${idx}${jdx}`} className={"static-image-card"}> */}
                      <div
                        href={cont.uri || "/#"}
                        key={`sc${idx}${jdx}`}
                        className={"static-image-card"}
                      >
                        <div>
                          <img
                            src={cont.url}
                            alt={cont.alt}
                            height={cont.height}
                            width={cont.width}
                          />
                          <br />
                          <h3 className="static_img_crd_lbl1">
                            {removePtag(cont.field_title) ||
                              removePtag(cont.title)}
                          </h3>
                          <h4 className="static_img_crd_lbl2">
                            {parseAsHtml(cont.field_para)}
                          </h4>
                        </div>
                      </div>
                    </div>
                  );

                  skipPara = true;
                  skipuri = true;
                }
                break;
              case "uri":
                if (!skipuri) {
                  midContent.push(
                    <div
                      className="col-md-12 circle-bullets"
                      key={`${idx}${jdx}`}
                    >
                      <a onClick={externalLinkDisclaimer}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={cont.uri}
                        title={cont.title}
                      >
                        {cont.title}
                      </a>
                    </div>
                  );
                }
                break;
              case "field_highlight_link":
                if (!skipuri) {
                  midContent.push(
                    <p className="col-md-4 my-3" key={`${idx}${jdx}`}>
                      <a onClick={externalLinkDisclaimer}
                        className="btn btn-common"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={cont[ke][0].uri}
                      >
                        {cont[ke][0].title}
                      </a>
                    </p>
                  );
                }
                break;
              case "field_flat_highlight_link":
                if (!skipuri) {
                  midContent.push(
                    <p className="col-md-4 my-3" key={`${idx}${jdx}`}>
                      <a onClick={externalLinkDisclaimer}
                        className="btn flat-button-orange external"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={cont[ke][0].uri}
                        title={cont[ke][0].title}
                      >
                        {cont[ke][0].title}
                      </a>
                    </p>
                  );
                }
                break;
              case "field_pimage":
                midContent.push(
                  <div key={`sc${idx}${jdx}`} className="col">
                    <img
                      className="img-responsive"
                      src={cont[ke][0].url}
                      alt={cont[ke][0].alt}
                    />
                  </div>
                );
                break;
              case "field_listing_image":
                midContent.push(
                  <div key={`sc${idx}${jdx}`} className="col">
                    <img
                      className="img-listing"
                      src={cont[ke][0].url}
                      alt={cont[ke][0].alt}
                    />
                  </div>
                );
                break;
              case "field_title":
                if (contentTitle === "") contentTitle = removePtag(cont[ke]);
                break;
              case "field_subtitle":
                cont[ke] ? (
                  midContent.push(
                    <div className="col-md-12 pt-2 mb-2" key={`sc${idx}${jdx}`}>
                      <div className="notes-section" key={`sc${idx}${jdx}`}>
                        <h3 className="notes-title">
                          {parseAsHtml(removePtag(cont[ke]))}
                        </h3>
                      </div>
                    </div>
                  )
                ) : (
                  <></>
                );
                break;
              case "field_highlight_title":
                midContent.push(
                  <div className="col-md-12 pt-2 mb-2" key={`sc${idx}${jdx}`}>
                    <div className="highlight-title" key={`sc${idx}${jdx}`}>
                      {removePtag(cont[ke])}
                    </div>
                  </div>
                );
                break;
              case "field_text":
                if (cont.file_url) {
                  midContent.push(
                    <div className="col-md-12 mt-2 mb-2" key={`sc${idx}${jdx}`}>
                      <a
                        className="download-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={cont.file_url}
                        key={`sc${idx}${jdx}`}
                        title={cont[ke]}
                        id={
                          "link" +
                          cont.file_url.substring(
                            cont.file_url.lastIndexOf("/") + 1
                          )
                        }
                        aria-label={cont.file_url.substring(
                          cont.file_url.lastIndexOf("/") + 1
                        )}
                      >
                        {getDownloadIcon(
                          cont.file_url,
                          cont.file_url.substring(
                            cont.file_url.lastIndexOf("/") + 1
                          )
                        )}
                        {cont[ke]}
                      </a>
                    </div>
                  );
                } else {
                  midContent.push(<p key={`sc${idx}${jdx}`}>{cont[ke]}</p>);
                }
                break;
              case "field_para":
                if (!skipPara) {
                  // midContent.push(<br key={`sc${idx}${jdx}`} />);

                  midContent.push(
                    <div className="col-md-12" key={`sc${idx}${jdx}`}>
                      <div key={`sc_${idx}${jdx}`} className="static-para">
                        {parseAsHtml(cont[ke])}
                      </div>
                    </div>
                  );
                }
                break;
              case "field_table_content":
                midContent.push(
                  <CustomTable
                    tableid={
                      this.state.menukey === "Membership" ||
                      this.state.menukey === "Technology"
                        ? `${pagkey}${idx}`
                        : `${pagkey}`
                    }
                    key={`sc${idx}${jdx}`}
                    data-columns={cont[ke][0]}
                    data-rows={cont[ke].slice(1, cont[ke].length - 1)}
                  />
                );

                break;
              case "field_event":
                midContent.push(
                  <EventGallery
                    key={`sc${idx}${jdx}`}
                    data-gallery={cont[ke].map((e, ix) => {
                      return e.field_event_card;
                    })}
                  />
                );
                break;
              case "field_tabs_content":
                midContent.push(
                  <div className="col-md-12" key={`sc${idx}${jdx}`}>
                    <NavTabs
                      staticpage={true}
                      key={`sc${idx}${jdx}`}
                      tabs={cont[ke].map((e, ix) => {
                        let tabContent = this.getMidComponent(
                          e.field_tab_view,
                          pgkey
                        ).midContent;
                        e.activeTabName = this.getActiveTab(e);
                        e.title = parseAsHtml(
                          removePtag(e.field_tab_view[0].field_title)
                        );
                        e.component = tabContent;
                        delete e.field_tab_view;
                        return e;
                      })}
                    />
                  </div>
                );
                break;
              case "field_activetabs":
                midContent.push(
                  <ActiveTabs
                    key={`at${idx}${jdx}`}
                    contents={cont[ke].map((e, ix) => {
                      let tabContent = this.getMidComponent(
                        e.field_tab_view,
                        pgkey
                      ).midContent;
                      e.title = e.field_tab_view[0].field_title;
                      e.body = tabContent;
                      delete e.field_tab_view;
                      return e;
                    })}
                    tabCount={cont[ke].length}
                  />
                );
                break;
              case "field_video_list_view":
                midContent.push(
				<VideoCard
                    key={`vid${idx}${jdx}`}
                    cardsize="s"
                    characterData={cont[ke].map((vid) => {
                      const videocard = vid.field_videocard;
                      // const vidIdIndx = videocard[2].uri.indexOf("=");
                      // console.log('vidIdIndx',vidIdIndx);
                    
                      return {
                        vid: `vid${idx}${jdx}`,
                        // vid: videocard[2].uri.substr(vidIdIndx).substring(1),
                        // vlink: (videocard[2] && videocard[2].uri) || "-",
                        // vImage: (videocard[1] && videocard[1].url) || "-",
                        // vcaption:
                        //   (videocard[0] && videocard[0].field_title) || "-",
                        vDesc: (videocard[0] && videocard[0].field_para) || "-",
                      };
                    })}
                  />
                );
                break;
              case "field_event_image_slider":
                midContent.push(
                  <ImageSlider
                    key={`is${idx}${jdx}`}
                    characterData={cont[ke].map((img) => {
                      return { mImage: img.url, alt: img.alt };
                    })}
                  />
                );
                break;
              case "field_collapsible_tab":
                midContent.push(
                  <CollapsibleTabs
                    key={`sc${idx}${jdx}`}
                    clpseTabs={cont[ke].map((e, ix) => {
                      console.log(`From static page`,e);
                      let tabContent = this.getMidComponent(
                        e.field_tab_view,
                        pgkey
                      ).midContent;
                      e.title = removePtag(e.field_tab_view[0].field_title);
                      e.body = tabContent;
                      delete e.field_tab_view;
                      return e;
                    })}
                  />
                );
                break;
              case "field_event_image":
                midContent.push(
                  <div key={`sc${idx}${jdx}`}>
                    <img
                      className="img-fluid"
                      src={cont[ke][0].url}
                      alt={cont[ke][0].alt}
                    />
                  </div>
                );
                break;
              case "field_list_view":
                midContent.push(
                  <div className="col-md-12" key={`sc${idx}${jdx}`}>
                    <ul className="circlelist pl-3">
                      {cont[ke].map((e, ix) => {
                        return (
                          <li key={`list${ix}`}>
                            {this.getMidComponent([e], pgkey).midContent}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
                break;
              case "field_cards":
                midContent.push(
                  <ProductInfoCard
                    key={`info${idx}${jdx}`}
                    prodcarddata={{ field_cards: cont[ke] }}
                  />
                );
                break;
              case "field_card_link":
                midContent.push(
                  <div className="col-md-4" key={`sc${idx}${jdx}`}>
                    <div className="document-card-link">
                      <div className="doc-icon">
                        {getDownloadIcon(
                          cont[ke][0].file_url,
                          cont[ke][0].field_text
                        )}
                      </div>
                      <a
                        className="download-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={cont[ke][0].file_url}
                        key={`sc${idx}${jdx}`}
                        title={cont[ke][0].field_text}
                      >
                        {cont[ke][0].field_text}
                      </a>
                    </div>
                  </div>
                );
                break;
              case "field_seo_components":
                metadetails = cont[ke];
                break;
              case "field_text_list":
                if (cont.file_url) {
                  midContent.push(
                    <div
                      className="col-md-12 circle-bullets"
                      key={`sc${idx}${jdx}`}
                    >
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={cont.file_url}
                        key={`sc${idx}${jdx}`}
                        title={cont[ke]}
                      >
                        {cont[ke]}
                      </a>
                    </div>
                  );
                } else {
                  midContent.push(<p key={`sc${idx}${jdx}`}>{cont[ke]}</p>);
                }
                break;
              case "field_table_contents":
                //Set unique id using Page key & index val for Table
                //Table from CMS is along with HTML structure
                midContent.push(
                  <div className="col-md-12" key={`sc${idx}${jdx}`}>
                    <div
                      key={`sc_${idx}${jdx}`}
                      className="responsive-static-table static-table"
                      id={
                        this.state.menukey === "Membership" ||
                        this.state.menukey === "Technology"
                          ? `${pagkey}${idx}`
                          : `${pagkey}`
                      }
                    >
                      {parseAsHtml(cont[ke])}
                    </div>
                  </div>
                );
                break;
              case "field_contact_us":
                Object.keys(cont[ke]).forEach((item) => {
                  contactusview.push(cont[ke][item]);
                });

                contactusview.forEach((item, idx) => {
                  if (idx === 0) {
                    AddressViewData.push(item);
                  } else {
                    PhoneNMailViewData.push(item);
                  }
                });
                midContent.push(
                  <ContactUsCard
                    key={`contactus_${idx}${jdx}`}
                    AddressViewData={AddressViewData}
                    PhoneNMailViewData={PhoneNMailViewData}
                  />
                );
                break;
              case "field_listing_grid":
                let listGrid = [];
                cont[ke].forEach((card, kdx) => {
                  if (
                    card.field_listing_grid_component &&
                    card.field_listing_grid_component[2]
                  ) {
                    let imgsrc = card.field_listing_grid_component[0];
                    let title =
                      card.field_listing_grid_component[1].field_title || "";
                    let para =
                      card.field_listing_grid_component[2].field_para || "";
                    listGrid.push(
                      <div key={kdx} className="col-md-3 mb-1">
                        <div className="issueinfo-card">
                          <div className="issue-img">
                            <img src={imgsrc.url} alt={imgsrc.alt} />
                          </div>
                          {card.field_listing_grid_component[0] &&
                            parseAsHtml(title)}
                          {card.field_listing_grid_component[1] &&
                            parseAsHtml(para)}
                        </div>
                      </div>
                    );
                  } else if (
                    card.field_listing_grid_component &&
                    card.field_listing_grid_component[1]
                  ) {
                    let imgsrc = card.field_listing_grid_component[0];
                    let para =
                      card.field_listing_grid_component[1].field_para || "";
                    let title =
                      card.field_listing_grid_component[1].title || "";
                    listGrid.push(
                      <div key={kdx} className="col-md-3 mb-2">
                        <div className="issueinfo-card">
                          <div className="issue-img">
                            <img src={imgsrc.url} alt={imgsrc.alt} />
                          </div>
                          {card.field_listing_grid_component[1] &&
                            removePtag(title)}
                          {card.field_listing_grid_component[1] &&
                            parseAsHtml(para)}
                        </div>
                      </div>
                    );
                  } else {
                    let uri = card.field_listing_grid_component[0].uri;
                    let title =
                      card.field_listing_grid_component[0].title || "";
                    listGrid.push(
                      <div key={kdx} className="col-md-4 mb-2">
                        <a href={uri} >
                          <div className="offer-card">
                            <h3 className="issue-title">
                              {card.field_listing_grid_component[0] &&
                                removePtag(title)}
                            </h3>
                          </div>
                        </a>
                      </div>
                    );
                  }
                });
                midContent.push(listGrid);

                break;
              case "field_listing_image_slider":
                midContent.push(<IssuerImageSlider data={cont[ke]} />);
                break;

              case "field_listing_card":
                let esgGrid = [];

                cont[ke].forEach((card, kdx) => {
                  let imgTag = "";
                  let bondFiles = [];
                  let bondText = "";
                  let bondDetails = [];
                  card.field_listing_card_component.forEach((bondCard, ldx) => {
                    let fieldCard = Object.keys(bondCard)[0];
                    switch (fieldCard) {
                      case "url":
                        imgTag = (
                          <img
                            src={bondCard.url}
                            height="50px"
                            width="50px"
                            alt={bondCard.alt}
                          />
                        );
                        break;
                      case "field_para":
                        bondText = bondCard.field_para;
                        break;

                      case "field_listing_text":
                        bondDetails.push(bondCard.field_listing_text);

                        break;
                      case "field_trade_download_file":
                        bondFiles.push(
                          <div>
                            <div className="esfBondFiles">
                              <div>
                                {parseAsHtml(
                                  bondCard.field_trade_download_file[0]
                                    .field_trade_text
                                )}
                              </div>
                              <a target="_blank" rel="noopener noreferrer"
                                href={
                                  bondCard.field_trade_download_file[0]
                                    .trade_file_url
                                }
                              >
                                <img
                                  src={esgDownload}
                                  alt="dowload"
                                  height="30px"
                                  width="30px"
                                ></img>
                              </a>
                            </div>
                          </div>
                        );
                        break;
                      default:
                        break;
                    }
                  });
                  if (card.field_listing_card_component) {
                    esgGrid.push(
                      <div key={kdx} className="row esgBlock">
                        <React.Fragment key={kdx}>
                          <div className="col-md-3 ">
                            <div className=" esgBlock1">
                              {imgTag}
                              <span>{removePtag(bondText)}</span>
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="esgBlock2">
                              <div className="row">
                                <div className="col-md-6">
                                  {parseAsHtml(bondDetails[0])}
                                </div>
                                <div className="col-md-6">
                                  {parseAsHtml(bondDetails[1]) || ""}
                                </div>
                              </div>
                              {bondFiles}
                            </div>
                          </div>
                        </React.Fragment>
                      </div>
                    );
                  }
                });
                midContent.push(
                  <div className="container-fluid">{esgGrid}</div>
                );
                break;

              case "field_listing_text_card":
                let infoGrid = [];
                cont[ke].forEach((card, kdx) => {
                  if (card.field_listing_text_component) {
                    let text = card.field_listing_text_component[0].field_title;

                    let para =
                      card.field_listing_text_component[1].field_para || "";

                    infoGrid.push(
                      <>
                        <div className="info-col">
                          {removePtag(text)}
                          <div className="value">{removePtag(para)}</div>
                        </div>
                      </>
                    );
                  }
                });

                midContent.push(
                  <div className="displayinfo">
                    <div className="info-row">{infoGrid}</div>
                  </div>
                );
                break;
                case "field_segregated_grid":
                  let segregatedGrid = [];
                  cont[ke].forEach((card, kdx) => {

                      let imgsrc = card.field_segregated_grid_component[0];
                      let title =
                        card.field_segregated_grid_component[1].field_title || "";
                      let para =
                        card.field_segregated_grid_component[2].field_para || "";
                      segregatedGrid.push(
                        <div key={kdx} className="col-md-4 mb-3">
                          <div className="segregated-card">
                            <div className="issue-img">
                              <img src={imgsrc.url} alt={imgsrc.alt} />
                            </div>
                            {card.field_segregated_grid_component[0] &&
                              parseAsHtml(title)}
                            {card.field_segregated_grid_component[1] &&
                              parseAsHtml(para)}
                          </div>
                        </div>
                      );

                  });
                  midContent.push(segregatedGrid);

                  break;
              default:
                break;
            }
          });
        });
        this.setState({ metadetails: metadetails });

        //Breadcrumb - set breadcrumb details of cms pages
        this.state.headerRef.current.updateTitle(breadcrumTitle, contentTitle, breadCrum, breadCrumImg);

        //Active Menu - Highlight which page & menu is active
        this.state.headerRef.current.activeMenu(this.state.menukey,this.state.pagekey);

        return { breadcrumTitle, contentTitle, midContent };
    };

    //Media Inner Page Content
    getMediaMidComponent = (pageContent) => {
        let midContent = [];
        let contentTitle = "";
        let breadcrumTitle = "";
        let breadCrum = "";
        let breadCrumImg = [];
        let metadetails = [];
        pageContent.forEach((cont, idx) => {
            let skipPara = false, skipuri = false;
            Object.keys(cont).forEach((ke, jdx) => {

                switch (ke) {
                    case "field_breadcrumb_image":
                        breadCrumImg = cont[ke];
                        break;
                    case "field_breadcrumb_view":
                        breadCrum = cont[ke];
                        break;
                    case "field_breadcrumb_title":
                        breadcrumTitle = removePtag(cont[ke]);
                        break;
                    case "url":
                        if (cont.alt && cont.height && cont.width && cont.uri) {
                            midContent.push(
                                <div className="col-md-4 my-3" key={`sc${idx}${jdx}`} >
                                    <a target="_blank" rel="noopener noreferrer" href={cont.uri || "/#"} key={`sc${idx}${jdx}`} className={"membership-card"}>
                                        <div className={"membership-div"}>
                                            <div className={"membership-img"}> <img src={cont.url} alt={cont.alt}
                                                height={cont.height} width={cont.width} />
                                            </div>
                                            <h5>{removePtag(cont.field_title) || removePtag(cont.title)}</h5>
                                        </div>
                                    </a>
                                </div>
                            );

                            skipPara = true
                            skipuri = true
                        }
                        else if (cont.alt && cont.height && cont.width && !cont.uri) {
                            midContent.push(
                                <div className="col-md-4 my-3 pl-4" key={`sc${idx}${jdx}`} >
                                    <a href={cont.uri || "/#"} key={`sc${idx}${jdx}`} className={"media-image static-image-card"}>
                                        <div>
                                            <img src={cont.url} alt={cont.alt}
                                                height={cont.height} width={cont.width} />
                                            <br /><h5>{removePtag(cont.field_title) || removePtag(cont.title)}</h5>
                                            <h6>{typeof cont.field_para === 'string' ? parseAsHtml(cont.field_para) : cont.field_para}</h6>
                                        </div>
                                    </a>
                                </div>
                            );

                            skipPara = true
                            skipuri = true
                        }
                        break;
                    case "uri":
                        if (!skipuri) {
                            if (cont.title === "View Details") {
                                midContent.push(
                                    <div className="col-md-12 inner-media mb-5" key={`${idx}${jdx}`}>
                                      <a target="_blank" rel="noopener noreferrer" className="btn btn-orange" href={cont.uri}>{cont.title}</a>
                                    </div>
                                );
                            }
                            else {
                                midContent.push(
                                    <div className="col-md-12 circle-bullets mb-3" key={`${idx}${jdx}`}><a href={cont.uri} title={cont.title}><ArrowBackIcon className="inner-pg-back-icon" />{cont.title}</a></div>
                                );
                            }
                        }

                        break;

                    // case "field_title":
                    //     if (contentTitle === "") contentTitle = removePtag(cont[ke]);


                    //     break;
                    case "field_subtitle":

                        midContent.push(<h3 className="col-md-12 pl-4 notes-title" key={`sc${idx}${jdx}`} >{removePtag(cont[ke])}</h3>)
                        break;

                    case "field_para":
                        if (!skipPara) {
                            midContent.push(<div className="col-md-8 pl-4" key={`sc${idx}${jdx}`} ><div key={`sc_${idx}${jdx}`} className="static-para">{parseAsHtml(cont[ke])}</div></div>);
                        }
                        break;
                    case "field_source_date":
                        if (!skipPara) {
                            midContent.push(<div className="col-md-6 pl-4 mb-3" key={`sc${idx}${jdx}`} ><div key={`sc_${idx}${jdx}`} className="static-para">{parseAsHtml(cont[ke])}</div></div>);
                        }
                        break;
                    case "field_source_text":
                        if (!skipPara) {
                            midContent.push(<div className="col-md-6 d-flex justify-content-end" key={`sc${idx}${jdx}`} ><div key={`sc_${idx}${jdx}`} className="static-para">{parseAsHtml(cont[ke])}</div></div>);
                        }
                        break;
                    case "field_seo_components":
                        metadetails = cont[ke];
                        break;
                    default:
                        break;
                    // Do Nothing;
                };
            });
        });
        this.setState({ metadetails: metadetails })
        this.state.headerRef.current.updateTitle(breadcrumTitle, contentTitle, breadCrum, breadCrumImg);
        return { breadcrumTitle, contentTitle, midContent };
    };

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        // return { hasError: false };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log("error rendering static page", error, errorInfo);
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={"main static_content" + (this.state.notFound ? " notfoundpage" : "")}
            id={"main-content" + this.state.pagekey || ""}>
                {/*Component for Meta - SEO details from CMS */}
                <MetaData metadetails={this.state.metadetails} />
                <div className="mid_body">
                    <section className="common-tabs">
                        <div className="container-fluid">
                            <div className="row">
                            {this.state.contentTitle!=="International Sustainability Exchange" &&
                                <div className={this.state.drawerOpen && this.state.showLeftPane ? "col-md-3" : "d-none"} id="leftNavWidth">
                                    <Drawer
                                        className={classes.drawer}
                                        variant="persistent"
                                        anchor="left"
                                        open={this.state.drawerOpen}
                                        classes={{
                                            paper: classes.drawerPaper
                                        }}
                                    >
                                        <div className="accordion" id="vieweditaccordion">

                                            <LeftNavigationContainer ref={this._leftPaneRef} menukey={this.state.menukey} pagekey={this.state.pagekey} />
                                        </div>
                                    </Drawer>
                                </div>}
                                <div className={this.state.drawerOpen && this.state.showLeftPane ? "col-md-9" : "col-md-12"}>
                                    {
                                        (this.state.innerPage) ||  (!this.state.notFound && this.state.contentTitle !=="" && <AppBar
                                            position="relative"
                                            className={clsx(classes.appBar, {
                                                [classes.appBarShift]: this.state.drawerOpen
                                            })}
                                        >
                                            <Toolbar className={!this.state.showLeftPane ? "pl-0" : ""}>
                                                {this.state.showLeftPane ?
                                                    <IconButton
                                                        color="inherit"
                                                        aria-label="open drawer"
                                                        onClick={this.state.drawerOpen ? this.handleDrawerClose : this.handleDrawerOpen}
                                                        edge="start"
                                                        className={clsx(classes.menuButton, this.state.drawerOpen)}
                                                    >
                                                        {this.state.drawerOpen ? <ChevronLeftIcon /> : <MenuIcon />}
                                                        {/* <button className="btn btn-orange-toggle">
                                                                        <i id="menu-toggle" className="fa fa-align-left navbar-toggler-icon line-height22" aria-hidden="true"></i></button> */}
                                                    </IconButton> : ""}
                                                {this.state.innerPage ? "" : <h2 className={"page-title " + (!this.state.showLeftPane ? "pl-0" : "")}>{parseAsHtml(this.state.contentTitle)}</h2>}
                                            </Toolbar>
                                        </AppBar>)
                                    }


                                    <div
                                        className={clsx(classes.content, {
                                            [classes.contentShift]: this.state.drawerOpen
                                        })}
                                    >
                                        {/* <h2 className="section-heading withborder">{this.state.contentTitle}</h2> */}
                                        <hr className="mt-2 hide" />
                                        {this.state.midContent ? <span /> : <LinearProgress />}
                                        <div className="row">
                                            {this.state.midContent}
                                        </div>
                                        <ScrollTop />
                                    </div>

                                </div>

                            </div>
                            {/* contentUpdatedAt */}
                            {/* <div className="row">
                                <div className="col text-right mt-4">
                                    <small><i>Updated on: {this.state.updatedTime}</i></small>
                                </div>
                            </div> */}
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(StaticPage);
