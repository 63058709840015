import React from 'react';
import { useState, useEffect, useRef } from 'react';
import _ from "lodash";
import './style.css';
import { makeStyles } from "@material-ui/core/styles";
import { Responsive, WidthProvider } from "react-grid-layout";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Minimize as MinimizeIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Select, MenuItem } from "@material-ui/core";
import Graph from "./Graph1";
import StreamerTable4 from './Streamer-table4';
import StreamerTable5 from './Streamer-table5';
import { State3toggle2 } from "./custom-toolbar";
import { convertNumberPrecision, getToggleVal } from './util';
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)"
    }
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  }
});


const columnStyle = {
  color: '#fff',
  fontWeight: '500'
}

const ButtonHeight = {
  height: '87%',
  paddingBottom: '10px',
}
const styleFont = {
  fontSize: '12px'
};

function ShowcaseLayout() {
  const [minimized, setMinimized] = useState(false);
  const [defaultInstrument, setdefaultInstrument] = useState('Select Segment');
  const [defaultData, setDefaultData] = useState("Instrument Type");
  const [defaultSymbolData, setDefaultSymbolData] = useState("Select Symbol");
  const [defaultExpiryData, setDefaultExpiryData] = useState("Expiry Date");
  const [instrumentType, setInstrumentType] = useState([{
    name: 'Future',
    value: 'FUT'
  },
  {
    name: 'Options',
    value: 'OPT'
  }]);
  const [instrument, setInstrument] = useState([{
    name: "Equity Derivatives",
    value: 'STK',
  },
  {
    name: "Index Derivatives",
    value: 'IDX',
  },
  {
    name: "Currency Derivatives",
    value: 'CUR',
  },
  {
    name: "Commodity Derivatives",
    value: 'COM',
  },
  {
    name: "US Stocks",
    value: 'USA',
  }])
  const classes = useStyles();
  const [token, setToken] = useState('default');
  const [data, setData] = useState([]);
  const [symbol, setSymbol] = useState([]);
  const [firstRow, setFirstRow] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [expiryDate, setExpiryDate] = useState([]);
  const [req_segment, setReq_segment] = useState();
  const [req_instrument_type, setReq_instrument_type] = useState();
  const [req_symbol, setReq_symbol] = useState();
  const [xAxis, setxAxis] = useState([]);
  const [marketContractSynopsis, setMarketContractSynopsis] = useState([]);
  const [dataMarketDepth, setDataMarketDepth] = useState([]);
  const [timeStamp, setTimeStamp] = useState();
  const [previousAskPrice, setPreviousAskPrice] = useState([]);
  let newArr = [];
  let new_SymbolArr = [];
  let new_ExpiryDate = [];
  let tokenNumber;
  const [state3tog, setState3Tog] = useState("Millions");
  const rowsRef = useRef([]);
  const rowsRef1 = useRef([]);

  useEffect(() => {
    setupMainTable();
    const intervalId = setInterval(() => {
      setupMainTable();
    }, 10000);

    return () => {
      clearInterval(intervalId);
    }
  }, [token]);

  const setupMainTable = async () => {
    const apiUrl1 = `https://www1.nseix.com/api/streamer-market-watch/`;
    const apiUrl2 = `https://www1.nseix.com/api/streamer-market-watch/${token}`;
    try {
      let response;
      if (token === 'default') {
        response = await fetch(apiUrl1);
      } else {
        response = await fetch(apiUrl2);
      }
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      setPreviousAskPrice(data);
      //setData([]);
      setData(responseData.MBP_data_Market_Watch);
      setFirstRow(responseData.MBP_data_Market_Watch[0]?.token_number);
    } catch (err) {
      console.log('error', err);
    }
  }

  tokenNumber = selectedRow ? selectedRow : firstRow;

  useEffect(() => {
    {
      data.forEach((item, key) => {
        const rowElement1 = rowsRef.current[key];
        const rowElement2 = rowsRef1.current[key];

        const key_tocheck = 'ASKPRICE';
        const key_tocheck1 = 'BIDPRICE';

        if (rowElement1) {
          const newPreviousValue = previousAskPrice[key]?.token_data[0][key_tocheck];
          if (newPreviousValue !== undefined && item.token_data[0][key_tocheck] !== newPreviousValue) {
            if (newPreviousValue !== '-' && item.token_data[0][key_tocheck] !== '-' && item.token_data[0][key_tocheck] > newPreviousValue) {
              rowElement1.classList.remove('priceDecreased')
              rowElement1.classList.add('priceIncreased');
              setTimeout(() => {
                rowElement1.classList.remove('priceIncreased');
              }, 2000);
            }
            if (newPreviousValue !== '-' && item.token_data[0][key_tocheck] !== '-' && item.token_data[0][key_tocheck] < newPreviousValue) {
              rowElement1.classList.remove('priceIncreased');
              rowElement1.classList.add('priceDecreased');
              setTimeout(() => {
                rowElement1.classList.remove('priceDecreased');
              }, 2000);
            }
          }
        }
        if (rowElement2) {
          const newBid_pricePreviousValue = previousAskPrice[key]?.token_data[0][key_tocheck1];
          if (newBid_pricePreviousValue !== undefined && item.token_data[0][key_tocheck1] !== newBid_pricePreviousValue) {
            if (newBid_pricePreviousValue !== '-' && item.token_data[0][key_tocheck1] !== '-' && item.token_data[0][key_tocheck1] > newBid_pricePreviousValue) {
              rowElement2.classList.remove('valueDecreased');
              rowElement2.classList.add('valueIncreased');
              setTimeout(() => {
                rowElement2.classList.remove('valueIncreased');
              }, 2000);
            }
            if (newBid_pricePreviousValue !== '-' && item.token_data[0][key_tocheck1] !== '-' && item.token_data[0][key_tocheck1] < newBid_pricePreviousValue) {
              rowElement2.classList.remove('valueIncreased');
              rowElement2.classList.add('valueDecreased');
              setTimeout(() => {
                rowElement2.classList.remove('valueDecreased');
              }, 2000);
            }
          }
        }

      })
    }
    setPreviousAskPrice(data);
  }, [data, previousAskPrice]);


  useEffect(() => {
    //Initial Call To Display Data
    //  setupMainTable();
    setupSecondTable();
    setupTimeStamp();
    //After Every 5 Seconds data Display
    const intervalId = setInterval(() => {
      //   setupMainTable();
      setupSecondTable();
      setupTimeStamp();
    }, 10000);

    return () => {
      clearInterval(intervalId);
    }
  }, [tokenNumber]);




  const setupSecondTable = async () => {
    try {
      if (tokenNumber) {
        const data = await fetch(`https://www1.nseix.com/api/streamer-market-depth-data/${tokenNumber}`);
        const jsonData = await data.json();
        setxAxis(jsonData.market_graph_data.data);
        setDataMarketDepth(jsonData.market_depth_data.token_market_depth_data);
        setMarketContractSynopsis(jsonData.contract_synopsis_data.token_market_contract_synopsis_data);
      }
    } catch (e) {
      console.log('errorr', e);
    }
  }






  const toggle3Switch = (checked) => {
    setState3Tog(checked);
  };

  const handleRowClick = (row) => {
    setSelectedRow(row.token_number);
  }

  const getStreamerInstrumentType = async (e) => {
    setdefaultInstrument(e.target.value);
    try {
      setReq_segment(e.target.value);
      const req_segment1 = e.target.value;
      const resp = await fetch('https://www1.nseix.com/api/streamer-filters?segment=' + req_segment1);
      const jsonData = await resp.json();
      if (jsonData != undefined) {
        await jsonData.data.map((data) => {
          newArr.push(data.MCM_TOKEN);
        });
        setReq_instrument_type(newArr);
        setFirstRow(newArr[0]);
        setToken(newArr);
      } else {
        console.log('token number is empty');
      }
    } catch (error) {
      console.log('error in fetching data', error);
    };
  };

  const instrumentTypeChange = async (e) => {
    newArr = [];
    setToken([]);
    setDefaultData(e.target.value)
    try {
      setReq_instrument_type(e.target.value);
      const req_instrument_type1 = e.target.value;
      const resp1 = await fetch('https://www1.nseix.com/api/streamer-filters?segment=' + req_segment + '&instrument_type=' + req_instrument_type1);
      const jsonData1 = await resp1.json();
      if (jsonData1 != undefined) {
        await jsonData1.data.map((data) => {
          newArr.push(data.MCM_TOKEN);
          if (!new_SymbolArr.includes(data.MCM_SYMBOL)) {
            new_SymbolArr.push(data.MCM_SYMBOL);
          }
        });
        setSymbol(new_SymbolArr);
        setToken(newArr);
      } else {
        console.log('token number is empty');
      }
    } catch (e) {
      console.log('error in catch data', e);
    }
  };

  const symbolChange = async (e) => {
    newArr = [];
    setToken([]);
    setDefaultSymbolData(e.target.value);
    try {
      setReq_symbol(e.target.value);
      const req_symbol1 = e.target.value;
      const resp2 = await fetch('https://www1.nseix.com/api/streamer-filters?segment=' + req_segment + '&instrument_type=' + req_instrument_type + '&symbol=' + req_symbol1);
      const jsonData2 = await resp2.json();
      if (jsonData2 != undefined) {

        await jsonData2.data.map((data) => {
          newArr.push(data.MCM_TOKEN);
          new_ExpiryDate.push(data.MCM_EXPIRY_DT);
        });
        setExpiryDate(new_ExpiryDate);
        setToken(newArr);
      } else {
        console.log('token number is empty');
      }
    } catch (e) {
      console.log('error in catch data', e);
    }
  }

  const handleExpiryDate = async (e) => {
    newArr = [];
    setDefaultExpiryData(e.target.value)
    try {
      const req_expiry_date1 = e.target.value;
      const resp3 = await fetch('https://www1.nseix.com/api/streamer-filters?segment=' + req_segment + '&instrument_type=' + req_instrument_type + '&symbol=' + req_symbol + '&expiry_date=' + req_expiry_date1);
      const jsonData3 = await resp3.json();
      if (jsonData3 != undefined) {
        await jsonData3.data.map((data) => {
          newArr.push(data.MCM_TOKEN);
        });
        setToken(newArr);
      } else {
        console.log('token number is empty');
      }
    } catch (e) {
      console.log('error in catch data', e);
    }
  }

  const reset = () => {
    setdefaultInstrument(defaultInstrument + 'Select Segment');
    setDefaultData(defaultData + 'Instrument Type');
    setDefaultSymbolData(defaultSymbolData + 'Select Symbol');
    setDefaultExpiryData(defaultExpiryData + 'Expiry Date');
    setToken('default');
  }



  const setupTimeStamp = async () => {
    try {
      const response1 = await fetch('https://www1.nseix.com/api/streamer-latest-timestamp');
      const jsonData4 = await response1.json();
      setTimeStamp(jsonData4);
    } catch (e) {
      console.log('error in catch data', e);
    }
  }




  const handleMinimized = () => {
    setMinimized(!minimized);
  }



  const gridItems = [
    { id: 1, name: "Item One" },
    { id: 2, name: "Item Two" },
    { id: 3, name: "Item Three" },
    { id: 4, name: "Item Four" },


  ];
  const layout = [
    { i: '1', x: 0, y: 0, w: 12, h: 2.5 },
    { i: '2', x: 0, y: 0, w: 6, h: 3 },
    { i: '3', x: 6, y: 3, w: 3, h: 3 },
    { i: '4', x: 9, y: 1, w: 3, h: 3 },
    // { i: '5', x: 12, y: 2, w: 3, h: 3 },

  ];
  const [rowHeight] = useState(150);
  return (
    <>
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-md-2">
            <Select defaultValue={defaultInstrument}
              className="customize-input-group quotes-select-filter"
              onChange={getStreamerInstrumentType}
              value={defaultInstrument}
            >
              <MenuItem value={defaultInstrument} selected="selected" disabled>Select Segment</MenuItem>
              {instrument && instrument.map((x, idx) => {
                return <MenuItem key={x + idx} value={x.value} >
                  {x.name}
                </MenuItem>
              })}
            </Select>
          </div>
          <div className="col-md-2">
            <Select defaultValue={defaultData}
              className="customize-input-group quotes-select-filter"
              onChange={instrumentTypeChange}
              value={defaultData}
            >
              <MenuItem value={defaultData} selected="selected" disabled>Instrument Type</MenuItem>
              {instrumentType.map((x, idx) => {
                return <MenuItem key={x + idx} value={x.value}>
                  {x.name}
                </MenuItem>
              })}
            </Select>
          </div>
          <div className="col-md-2">
            <Select defaultValue={defaultSymbolData}
              className="customize-input-group quotes-select-filter"
              onChange={symbolChange}
              value={defaultSymbolData}
            >
              <MenuItem value={defaultSymbolData} selected="selected" disabled>Select Symbol</MenuItem>
              {symbol ?
                symbol.map((x, idx) => {
                  return <MenuItem key={x + idx} value={x} >
                    {x}
                  </MenuItem>
                }) :
                <MenuItem selected="selected" disabled>No Symbol</MenuItem>
              }
            </Select>
          </div>
          <div className="col-md-2">
            <Select defaultValue={defaultExpiryData}
              className="customize-input-group quotes-select-filter"
              onChange={handleExpiryDate}
              value={defaultExpiryData}
            >
              <MenuItem value={defaultExpiryData} selected="selected" disabled>Expiry Date</MenuItem>
              {expiryDate.map((x, idx) => {
                return <MenuItem key={x + idx} value={x}>
                  {x}
                </MenuItem>
              })}
            </Select>
          </div>
          <div className="col-sm-1 quotes-filter">
            <button className="btn btn-orange full-width" style={{ ButtonHeight }} onClick={reset}>Clear</button>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
          <div className='muidatatable-timestamp' style={{ color: 'grey', flex: ' 1 1 auto', display: 'flex', alignItems: 'center' }}>Market as on {timeStamp && timeStamp[0]}   </div>
          <div style={{ flex: 'initial', display: 'flex', alignItems: 'center', textAlign: 'right', marginRight: '20px' }}><State3toggle2 toggleCallback={toggle3Switch} defaultState={'Millions'} /></div>
        </div>
      </div>
      <ResponsiveReactGridLayout
        layouts={{ lg: layout }}
        measureBeforeMount={false}
        className="layout"
        rowHeight={rowHeight}
        isDraggable={true}
        isResizable={false}
        margin={[20, 20]}
      >
        {gridItems.map((item, i) => {


          if (item.id == 1) {
            return (<div key={item.id} className="grid-item" style={{ backgroundColor: 'white', }} >

              <div className='card' >
                <div className='card-header' style={{ backgroundColor: '#392d7d', color: 'white' }} >
                  <b>Market Watch</b>
                  <IconButton edge="end" color="inherit" onClick={handleMinimized} style={{ float: 'right' }}>
                    <MinimizeIcon />
                  </IconButton>
                </div>
                {minimized ? (
                  <div style={{ display: 'none' }}></div>
                ) : (

                  <div className='card-body'>
                    <div>
                      <TableContainer component={Paper} style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <Table className="table customize-table" style={{ width: '100%' }} >
                          <TableHead>
                            <TableRow className={classes.stickyHeader} style={{ backgroundColor: '#392d7d', color: 'white' }}>
                              <>
                                <TableCell style={columnStyle}>Instrument Type
                                </TableCell>
                                <TableCell style={columnStyle} >Symbol
                                </TableCell>
                                <TableCell style={columnStyle}>Expiry Date
                                </TableCell>
                                <TableCell style={columnStyle}>Option Type
                                </TableCell>
                                <TableCell style={columnStyle}>Strike Price
                                </TableCell>
                                <TableCell style={columnStyle}>Bid Quantity
                                </TableCell>
                                <TableCell style={columnStyle}>Bid Price
                                </TableCell>
                                <TableCell style={columnStyle}>Ask Price
                                </TableCell>
                                <TableCell style={columnStyle}>Ask Quantity
                                </TableCell>
                                <TableCell style={columnStyle}>Last Price
                                </TableCell>
                                <TableCell style={columnStyle}>Change
                                </TableCell>
                                <TableCell style={columnStyle}>Per Change
                                </TableCell>
                                <TableCell style={columnStyle}>Volume
                                </TableCell>
                                <TableCell style={columnStyle}>Value
                                </TableCell>
                                <TableCell style={columnStyle}>ATP
                                </TableCell>
                                <TableCell style={columnStyle}>LTT
                                </TableCell>
                              </>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.map((data_new, key) => {
                              return (<TableRow key={data_new + key} onClick={() => handleRowClick(data_new)} selected={selectedRow === data_new} style={{ backgroundColor: selectedRow === data_new.token_number ? 'lightblue' : 'inherit' }}>
                                <TableCell style={styleFont}>{data_new.token_data[0].INSTRUMENT}</TableCell>
                                <TableCell style={styleFont}>{data_new.token_data[0].SYMBOL}</TableCell>
                                <TableCell style={styleFont}>{data_new.token_data[0].EXPIRYDATE}</TableCell>
                                <TableCell style={styleFont}>{data_new.token_data[0].OPTIONTYPE}</TableCell>
                                <TableCell style={styleFont}>{data_new.token_data[0].STRIKEPRICE}</TableCell>
                                <TableCell style={styleFont}>{data_new.token_data[0].BIDQTY}</TableCell>
                                <TableCell className='bidPrice' ref={(el) => (rowsRef1.current[key] = el)} style={styleFont}>{data_new.token_data[0].BIDPRICE}</TableCell>
                                <TableCell className='askPrice' ref={(el) => (rowsRef.current[key] = el)} style={styleFont}>{data_new.token_data[0].ASKPRICE}</TableCell>
                                <TableCell style={styleFont}>{data_new.token_data[0].ASKQTY}</TableCell>
                                <TableCell style={styleFont}>{data_new.token_data[0].LASTPRICE}</TableCell>
                                <TableCell style={styleFont}>{data_new.token_data[0].CHANGE}</TableCell>
                                <TableCell style={styleFont}>{data_new.token_data[0].PERCHANGE}</TableCell>
                                <TableCell style={styleFont}>{data_new.token_data[0].VOLUME}</TableCell>
                                <TableCell style={styleFont}>{convertNumberPrecision(getToggleVal(data_new.token_data[0].VALUE ? data_new.token_data[0].VALUE : 0, state3tog))}</TableCell>
                                <TableCell style={styleFont}>{data_new.token_data[0].ATP}</TableCell>
                                <TableCell style={styleFont}> {data_new.token_data[0].LTT}</TableCell>
                              </TableRow>)
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>

                )
                }

              </div>
            </div>)
          }
          else if (item.id == 2) {
            return (<div key={item.id} className="grid-item" style={{ backgroundColor: 'white', }} >

              <div className='card' style={{ height: '100%' }}>
                <div className='card-header' style={{ backgroundColor: '#392d7d', color: 'white' }} >
                  <b>Market Graph </b>
                </div>
                <div className='card-body'>
                  <Graph dataX={xAxis} />
                </div>
              </div>
            </div>)
          }
          else if (item.id == 3) {
            return (<div key={item.id} className="grid-item" style={{ backgroundColor: 'white', }} >
              <StreamerTable4 newData={dataMarketDepth} />
            </div>)
          }
          else if (item.id == 4) {
            return (<div key={item.id} className="grid-item" style={{ backgroundColor: 'white', }} >

              <StreamerTable5 newData={marketContractSynopsis} />
            </div>)
          }

        })}
      </ResponsiveReactGridLayout>
    </>
  );

}

export default ShowcaseLayout;
