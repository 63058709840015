import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const myStyleWeight = {
  backgroundColor: 'rgba(59, 46, 126, 0.17)', 
  color: '#392d7d', 
  fontWeight: '500' 
};
const myStyleSize = {
  fontSize:'12px'
}
function StreamerTable5({ newData}){

    return(
        <div className='card' style={{ height: '100%' }}>
        <div className='card-header' style={{ backgroundColor: '#392d7d', color: 'white' }} >
          <b>Contract Synopsis</b>
        </div>
        <div className='card-body'>
          <TableContainer component={Paper}>
            <Table className="table customize-table" aria-label="simple table">
              <TableBody >
                {newData.map((value)=>{
                    return(
                <>
                <TableRow  >
                  <TableCell style={myStyleWeight}>Prev Close Price</TableCell>
                  <TableCell style={myStyleSize}>{value.PREVCLOSERATE}</TableCell>
                  <TableCell style={myStyleWeight}>Last Traded Time</TableCell>
                  <TableCell style={myStyleSize}>{value.TIME}</TableCell>
                </TableRow>
                <TableRow >
                  <TableCell style={myStyleWeight}>Open</TableCell>
                  <TableCell style={myStyleSize}>{value.OPEN}</TableCell>
                  <TableCell style={myStyleWeight}>Price</TableCell>
                  <TableCell style={myStyleSize}>{value.PRICE}</TableCell>
                </TableRow>
                <TableRow >
                  <TableCell style={myStyleWeight}>High</TableCell>
                  <TableCell style={myStyleSize}>{value.HIGH}</TableCell>
                  <TableCell style={myStyleWeight}>Last Traded Qty</TableCell>
                  <TableCell style={myStyleSize}>{value.QTY}</TableCell>
                </TableRow>
                <TableRow >
                  <TableCell style={myStyleWeight}>Low</TableCell>
                  <TableCell style={myStyleSize}>{value.LOW}</TableCell>
                  <TableCell style={myStyleWeight}>Change</TableCell>
                  <TableCell style={myStyleSize}>{value.CHANGE}</TableCell>
                </TableRow>
                <TableRow >
                  <TableCell style={myStyleWeight}>Volume</TableCell>
                  <TableCell style={myStyleSize}>{value.VOLUME}</TableCell>
                  <TableCell style={myStyleWeight}>Change %</TableCell>
                  <TableCell style={myStyleSize}>{value.PERCHANGE}</TableCell>
                </TableRow>
                <TableRow >
                  <TableCell style={myStyleWeight}>Avg</TableCell>
                  <TableCell style={myStyleSize}>{value.AVG}</TableCell>
                  <TableCell style={myStyleWeight}>Turnover (Mn)</TableCell>
                  <TableCell style={myStyleSize}>{value.TURNOVER}</TableCell>
                </TableRow>
                </>)
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    )
}

export default StreamerTable5;
